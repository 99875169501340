import { ReactElement } from 'react'
import { Title } from 'react-head'

import { ReleaseMetaTagsFragment, ReleaseTitleFragment, ReleaseVisibility } from '../../api/types'
import { isAnyVarious } from '../../lib/is-various'
import { collateArtists, artistName } from '../../lib/collate-artists'
import { year } from '../../lib/date'

export type Props = ReleaseTitleFragment & {
    itemType: string
}

type Format = {
    description?: string[]
    name: string
    quantity?: string
    text?: string
}

type GroupedFormat = {
    name: string
    totalQuantity: number
}

type ArtistsNames = NonNullable<NonNullable<ReleaseMetaTagsFragment['primaryArtists']>[number]>

export function ReleaseTitle(props: Props): ReactElement {
    return <Title>{releaseTitle(props)}</Title>
}

export function releaseTitle(props: Props): string {
    const { title, released, formats = [], primaryArtists, visibility, itemType, discogsId } = props

    function artistsNames(primaryArtists: ArtistsNames[] | undefined): string {
        if (!primaryArtists || primaryArtists.length === 0) {
            return ''
        }

        return collateArtists(primaryArtists, artistName).join('')
    }

    /**
     * Iterates over Formats[] and groups them in an object
     * @param f - An array of Formats
     * @returns Object - e.g.: { name: 'Vinyl', totalQuantity: 2 }
     */
    function groupByFormat(f: Format[]) {
        type Acc = {
            [key: string]: GroupedFormat
        }
        return f.reduce((acc: Acc, format: Format) => {
            const { name = '' } = format
            const quantity = Number(format.quantity)
            /* eslint-disable @typescript-eslint/no-unnecessary-condition */
            if (!acc[name]) {
                acc[name] = { name, totalQuantity: 0 }
            }
            acc[name].totalQuantity += quantity
            return acc
        }, {})
    }

    /**
     * Sums the number of formats and includes the format name in the returned string
     * @param formatQty - An object with name and totalQuantity properties
     * @returns String - e.g.: 2 x Vinyl
     */
    function stringifyFormats(formatQty: GroupedFormat[]) {
        return formatQty.map((item: GroupedFormat) => {
            if (item.totalQuantity === 1) {
                return item.name
            }
            return `${item.totalQuantity} x ${item.name}`
        })
    }

    /**
     * Removes all instances of the specified format from the format array
     * @param formatName - The name of the format to exclude
     * @returns Array
     */
    function excludeFormat(formatName: string) {
        return formats.filter((format) => format.name !== formatName)
    }

    /**
     * `info` is ultimately used in the page title.
     * It will be reassigned if the release contains both 'Box Set' and 'All Media'.
     * 'All Media' is preferred in such cases.
     */
    let info = ''

    const draftStatus = visibility === ReleaseVisibility.Unlisted ? 'Draft of ' : ''

    const artists =
        !primaryArtists || isAnyVarious(primaryArtists)
            ? undefined
            : collateArtists(primaryArtists, artistName).join('').concat(' – ')

    const releaseYear = released ? year(released) : ''

    const isBoxset = formats.some((format) => format.name === 'Box Set')
    const boxset = isBoxset && formats.find((format) => format.name === 'Box Set')

    if (boxset) {
        const { text = '', description = [], name = '' } = boxset
        const f = excludeFormat('Box Set')
        const formatAndQty = Object.values(groupByFormat(f))
        const stringifiedFormats = stringifyFormats(formatAndQty)
        const descriptionText = description?.length ? `${description?.join(', ')}` : ''
        const freetext = text?.length ? text : ''
        const spacer = descriptionText.length && freetext.length ? ', ' : ''
        const ftAndDesc =
            freetext.length || descriptionText?.length ? ` (${freetext}${spacer}${descriptionText}): ` : ' '
        info = `${name}${ftAndDesc}${stringifiedFormats.join(', ')}, ${releaseYear} [r${discogsId}]`
    }

    const isAllMedia = formats.some((format) => format.name === 'All Media')
    const allMedia = isAllMedia && formats.find((format) => format.name === 'All Media')

    if (allMedia) {
        const { text = '', description = [] } = allMedia
        const f = excludeFormat('All Media')
        const formatAndQty = Object.values(groupByFormat(f))
        const stringifiedFormats = stringifyFormats(formatAndQty)
        const [mainFormat, ...rest] = stringifiedFormats
        const restText = rest.length ? ` + ${rest.join(', ')},` : ','
        const descriptionText = description?.length ? `${description?.join(', ')}` : ''
        const freetext = text?.length ? text : ''
        const spacer = descriptionText.length && freetext.length ? ', ' : ''

        info = `${mainFormat} (${freetext}${spacer}${descriptionText})${restText} ${releaseYear} [r${discogsId}]`
    }

    if (!boxset && !allMedia) {
        const quantityAndFormats: String[] = []

        formats.forEach((format) => {
            const { text = '', name = '', description = [], quantity = '' } = format
            const freetext = text?.length ? text : ''
            const descriptionText = description?.length ? `${description?.join(', ')}` : ''
            const spacer = descriptionText.length && freetext.length ? ', ' : ''
            const qty = quantity.length ? quantity : ''
            let desc = ''

            if (description?.length > 3) {
                const { length } = description
                const truncated = description.slice(0, 2).join(', ')
                const n = length - 2
                desc = `${truncated} + ${n} more`
            } else {
                desc = descriptionText
            }

            quantityAndFormats.push(
                qty === '1'
                    ? `${name} (${freetext}${spacer}${desc})`
                    : `${qty} x ${name} (${freetext}${spacer}${desc})`,
            )
        })

        info = [quantityAndFormats.join(' + '), `${releaseYear} [r${discogsId}]`].join(', ')
    }

    const wrapped = info.length > 0 ? ` – ${info}` : ''

    return itemType === 'MASTER_RELEASE'
        ? `${artistsNames(primaryArtists)} – ${title} | Releases | Discogs`
        : `${draftStatus}${artists ?? ''}${title}${wrapped} | Discogs`
}
