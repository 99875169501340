import { MouseEvent, ReactElement } from 'react'
import classnames from 'classnames'
import { Trans, t } from '@lingui/macro'
import { useLocalisation } from '../../../lib/i18n'

import { ConcatenatedLinks, ConcatenatedLink } from '@discogs/amped/concatenated-links'
import { Link } from '@discogs/amped/link'
import { Picture, ImageSourceProps } from '@discogs/amped/picture'
import { CardFormat } from '../cardFormat'
import { NSFWComponent } from './nsfw-image-placeholder'
import { Skittle } from '../skittle'

import css from './styles.css'
import { AnchorButton, Button } from '@discogs/amped/button'
import { Typography } from '@mui/material'

export type CardProps = {
    /**
     * The ID of the entity (release/artist/label/etc). Can be a discogsId.
     */
    discogsId: number
    /**
     * Did the user just add or remove the item from their wantlist?
     */
    itemIsInWantlist: boolean
    /**
     * Does the wantlist button add or remove?
     */
    clickHandler?: (evt: MouseEvent<HTMLButtonElement>) => void
    /**
     * The formats of the release.
     */
    formats?: { name: string; description?: string[] }[]
    /**
     * The title of the card.
     */
    title: string
    /**
     * Path for the card, like /master/565-Aphex-Twin-Selected-Ambient-Works-85-92.
     */
    siteUrl: string
    /**
     * The primary artists involved in the creation of the release.
     */
    primaryArtists?: ConcatenatedLink[]
    /**
     * The date when the release was released.
     */
    released?: string
    /**
     * The country where the release was released.
     */
    country?: string
    /**
     * Is image flagged by HIVE as a nsfw image
     */
    nsfw?: boolean
    /**
     * The string displayed on the NSFW component
     */
    nsfwText?: string
    /**
     * Images used by Release.
     */
    images: {
        edges: {
            node?: { id: string; thumbnail: ImageSourceProps }
        }[]
    }
    /**
     * Show shop/want buttons
     */
    showCTAButtons?: boolean
    /**
     * Responsive
     */
    responsive?: boolean
}

export function Card(props: CardProps): ReactElement | null {
    const {
        images,
        title,
        formats,
        siteUrl,
        primaryArtists,
        released,
        country,
        clickHandler,
        nsfw = false,
        nsfwText = '',
        showCTAButtons = false,
        discogsId,
        itemIsInWantlist,
        responsive = false,
    } = props
    const { i18n } = useLocalisation()
    const format = formats?.[0]?.name ?? 'Vinyl'
    const formatDescriptions = formats?.[0]?.description ?? null

    const artist = primaryArtists?.[0].artist

    if (!artist) {
        return null
    }

    const alt = `${artist.name} - ${title}`
    const thumbnail = images.edges[0]?.node?.thumbnail

    function displayImage(): ReactElement {
        if (nsfw) {
            return <NSFWComponent displayText={nsfwText} />
        }
        return (
            <Picture
                thumbnail={thumbnail}
                alt={alt}
                format={format}
                width={134}
                height={134}
                className={css.thumbnail}
            />
        )
    }

    function handleClick(evt: MouseEvent<HTMLButtonElement>) {
        if (clickHandler) {
            clickHandler(evt)
        }
    }

    return (
        <figure className={classnames(css.card, responsive ? css.responsiveCard : null)}>
            <div className={css.thumbnailContainer}>
                {itemIsInWantlist && (
                    <div className={css.skittleContainer}>
                        <Skittle type='wantlist' count={1} />
                    </div>
                )}
                <Link href={siteUrl} aria-label={alt}>
                    {displayImage()}
                </Link>
            </div>
            {/* Note on the use of Important below: used to override amped component, */}
            <figcaption>
                <div>
                    <div className={css.titleAndArtist}>
                        <Link href={siteUrl} className={css.title} title={title}>
                            {title}
                        </Link>
                        <Typography
                            component='span'
                            variant='labelXSmall'
                            sx={{
                                '& *': {
                                    fontSize: '.875rem !important',
                                },
                                '& *:focus': {
                                    color: 'white !important',
                                },
                            }}
                        >
                            <ConcatenatedLinks artists={primaryArtists} />
                        </Typography>
                    </div>
                    <Typography
                        component='span'
                        sx={{
                            '& *': {
                                fontSize: '.75rem !important',
                            },
                        }}
                    >
                        <Released released={released} country={country} />
                    </Typography>
                    <CardFormat primaryFormat={format} formatDescriptions={formatDescriptions} />
                </div>
            </figcaption>
            {showCTAButtons && (
                <div className={css.ctaButtonContainer}>
                    <AnchorButton
                        className={css.ctaButton}
                        href={`/sell/release/${discogsId}`}
                        aria-label={t(i18n)`Shop`}
                        dense
                    >
                        <Trans>Shop</Trans>
                    </AnchorButton>
                    <Button
                        className={css.ctaButton}
                        variant={itemIsInWantlist ? 'primary' : 'secondary'}
                        aria-label={t(i18n)`Add to wantlist`}
                        dense
                        onClick={handleClick}
                    >
                        <Trans>Want</Trans>
                    </Button>
                </div>
            )}
        </figure>
    )
}

type ReleaseDateProps = {
    /**
     * Release date.
     */
    released?: string
    /**
     * Release country.
     */
    country?: string
}

function formatDate(date: ReleaseDateProps['released']) {
    if (!date) {
        return null
    }
    if (date.length === 4) {
        return date
    }
    const dateFragments = date.split('-')
    return dateFragments[0]
}

export function Released(props: ReleaseDateProps): ReactElement {
    const { released, country } = props

    return (
        <div className={classnames(css.released)}>
            <div className={css.dateAndCountry}>
                {released ? formatDate(released) : null}
                {released && country ? ' ' : null}
                {country ? country : null}
            </div>
        </div>
    )
}
