import { ReactElement, MouseEvent, useState } from 'react'
import { t, Trans } from '@lingui/macro'

import { useLocalisation } from '../../lib/i18n'
import { WithProps } from '../../lib/add-state'

import { useAddReleasesToWantlistMutation } from '../../mutations/add-releases-to-wantlist'
import { useRemoveReleasesFromWantlistMutation } from '../../mutations/remove-releases-from-wantlist'
import { login, useLogin } from '../../lib/login'

import { Section } from '../../lib/components/section'
import { HorizontalScrollContainer } from '@discogs/amped/horizontal-scroll-container'
import { Card } from '../../lib/components/card'
import { Props, State, Action } from '.'
import { useInteraction } from '../../lib/components/analytics'
import { ReleaseCardFragment } from '../../api/types'

type UIProps = WithProps<Props, State, Action>

export function RecommendationsUI(props: UIProps): ReactElement | null {
    const { i18n } = useLocalisation()

    const {
        isOffensive,
        recommendations: { totalCount, edges },
    } = props

    if (totalCount <= 0 || isOffensive) {
        return null
    }

    const cards = edges
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        .filter((edge) => edge.node.images && edge.node.siteUrl && edge.node.title && !edge.node.inWantlist)
        .map((edge, i) => (
            <Recommendation
                // eslint-disable-next-line react/no-array-index-key
                key={`recommendation-card-${i}`}
                {...edge.node}
            />
        ))

    const labels = {
        'Scroll left': t(i18n)`Scroll left`,
        'Scroll right': t(i18n)`Scroll right`,
    }

    // do not render the recommendations component if all are in the users wantlist
    // otherwise we render an empy section
    const allInWantlist = cards.some((obj) => obj.props.inWantlist === null)

    return allInWantlist ? (
        <Section id='release-recommendations' title={<Trans>Recommendations</Trans>}>
            <HorizontalScrollContainer labels={labels}>{cards}</HorizontalScrollContainer>
        </Section>
    ) : null
}
type RecommendationsProps = Partial<ReleaseCardFragment> & { key: string }

export function Recommendation(props: RecommendationsProps): ReactElement | null {
    const { loggedIn } = useLogin()
    const { i18n } = useLocalisation()

    const { images, title, formats, siteUrl, primaryArtists, released, country, discogsId = 0, inWantlist } = props
    // do not recommend titles that are already in a users wantlist
    const itemIsInWantlist = Boolean(inWantlist)
    // seperate local state to prevent the recommendation
    // from dissapearing when the user adds it to their wantlist.
    const [added, setAdded] = useState(false)

    const addw = useAddReleasesToWantlistMutation()
    const removew = useRemoveReleasesFromWantlistMutation()
    const track = useInteraction()

    function addToWantlist(evt: MouseEvent<HTMLButtonElement>): void {
        evt.stopPropagation()
        evt.preventDefault()
        if (!loggedIn) {
            login()
            return
        }
        setAdded(true)
        track('Add to Wantlist')
        void addw.perform({ discogsIds: [discogsId] })
    }

    function removeFromWantlist(evt: MouseEvent<HTMLButtonElement>): void {
        evt.stopPropagation()
        evt.preventDefault()
        if (!loggedIn) {
            login()
            return
        }
        setAdded(false)
        void removew.perform({ discogsIds: [discogsId] })
    }

    const clickHandler = added ? removeFromWantlist : addToWantlist

    if (!images || !title || !siteUrl || itemIsInWantlist) {
        return null
    }

    const nsfwText = t(i18n)`Image hidden`
    const isNSFW = !loggedIn && images.edges[0]?.node?.nsfw

    return (
        <Card
            clickHandler={clickHandler}
            itemIsInWantlist={added}
            key={`${discogsId}-release`}
            images={images}
            title={title}
            formats={formats}
            siteUrl={siteUrl}
            primaryArtists={primaryArtists}
            released={released}
            country={country}
            discogsId={discogsId}
            nsfw={isNSFW}
            nsfwText={nsfwText}
            showCTAButtons
        />
    )
}
