import { useEffect, useState } from 'react'
import { useDataLayer, DataLayerEvent } from '../../lib/data-layer'
import { ReleaseAdTargetingFragment, ItemType } from '../../api/types'

import { isAnyVarious } from '../../lib/is-various'
import { year as getYear } from '../../lib/date'
import { collateArtists, artistName } from '../../lib/collate-artists'
import { useCookie } from '../../lib/cookies'
import { useLogin } from '../../lib/login'

export type ReleaseAdTargetingEvent = DataLayerEvent & {
    event: 'ad-targeting'
    itemType: ItemType
    title?: string
    artist?: string
    year?: number
    ids: string[]
    genres: string[]
    styles: string[]
    formats: string[]
}

type Format = NonNullable<ReleaseAdTargetingFragment['formats']>[number]
type Artist = NonNullable<NonNullable<ReleaseAdTargetingFragment['primaryArtists']>[number]>
type Label = NonNullable<NonNullable<ReleaseAdTargetingFragment['labels']>[number]>

export type Props = ReleaseAdTargetingFragment & { itemType: ItemType }

export function ReleaseAdTargeting(props: Props): null {
    const {
        discogsId,
        itemType,
        isOffensive,
        title,
        genres,
        styles,
        formats: formatsData,
        primaryArtists,
        released,
        labels,
        masterRelease,
    } = props

    const [targetingGroup, setTargetingGroup] = useCookie('ad-test-group')
    // eslint-disable-next-line react/hook-use-state
    const [group] = useState(targetingGroup ?? (Math.random() < 0.5 ? 'a' : 'b'))
    const { user } = useLogin()

    useEffect(function () {
        if (!targetingGroup) {
            setTargetingGroup(group)
        }
    }, [])

    const artistIds = (primaryArtists ?? []).map(function (artist: Artist): string | null {
        if (!artist.artist || !('discogsId' in artist.artist)) {
            return null
        }

        const { discogsId } = artist.artist
        return `a${discogsId}`
    })

    const labelIds = (labels ?? []).map(function (label: Label): string | null {
        if (!('discogsId' in label.label)) {
            return null
        }

        const { discogsId } = label.label
        return `l${discogsId}`
    })

    const year = released ? getYear(released) : undefined
    const formats = formatsData?.map((format: Format): string => format.name) ?? []

    const artist =
        primaryArtists && !isAnyVarious(primaryArtists) && collateArtists(primaryArtists, artistName).join('')

    const ids = [`r${discogsId}`, ...(masterRelease ? [`m${masterRelease.discogsId}`] : []), ...artistIds, ...labelIds]

    const event: ReleaseAdTargetingEvent = {
        event: 'ad-targeting',
        itemType,
        title,
        artist: artist && artist.length > 0 ? artist : undefined,
        year,
        ids: ids.filter((id: string | null): id is string => Boolean(id)),
        genres: genres ?? [],
        styles: styles ?? [],
        formats,
    }

    if (!user) {
        event['ad-targeting-group'] = group
    }

    useDataLayer({ event, ssr: true, skip: isOffensive })

    return null
}
