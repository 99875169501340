import { ReactElement } from 'react'
import classnames from 'classnames'

import css from './styles.css'

export type NSFWComponentProps = {
    displayText: string
}

export function NSFWComponent(props: NSFWComponentProps): ReactElement {
    const { displayText } = props
    return <div className={classnames(css.notSafeForWorkImageContainer)}>{displayText}</div>
}
