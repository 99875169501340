import { ReactElement } from 'react'
import classnames from 'classnames'

import css from './styles.css'
import { Vinyl, Cassette, Digital, Cd } from '@discogs/amped/icons'
import { ConcatenatedText } from '@discogs/amped/concatenated-text'

type FormatProps = {
    /**
     * Format description / sub-type.
     */
    formatDescriptions?: string[] | null
    /**
     * Format name / primary type.
     */
    primaryFormat: string
}

/**
 * A map of format names to the classname of the icon to display
 * the format with.
 */
export const formatIcons = {
    CD: Cd,
    CDr: Cd,
    DVD: Cd,
    Cassette: Cassette,
    VHS: Cassette,
    'U-Matic': Cassette,
    'Betacam SP': Cassette,
    'Reel-To-Reel': Cassette,
    File: Digital,
    Microcassette: Cassette,
    Minidisc: Cd,
    Vinyl: Vinyl,
    'Box Set': Cd,
    'Blu-ray': Cd,
    'All Media': Cd,
    SACD: Cd,
    Shellac: Vinyl,
    'Flexi-disc': Vinyl,
    Unknown: Vinyl,
}

export function CardFormat(props: FormatProps): ReactElement {
    const { primaryFormat = 'Vinyl', formatDescriptions = null } = props
    const nameWithHyphen = formatDescriptions && formatDescriptions.length > 0 ? `${primaryFormat} — ` : primaryFormat

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const IconComponent = formatIcons[primaryFormat as keyof typeof formatIcons]
        ? formatIcons[primaryFormat as keyof typeof formatIcons]
        : // eslint-disable-next-line dot-notation
          formatIcons['Vinyl']

    return (
        <div className={classnames(css.format)}>
            <IconComponent className={css.formatIcon} />
            <span className={css.primaryFormat}>{nameWithHyphen}</span>

            {formatDescriptions ? <ConcatenatedText items={formatDescriptions} joiningText=', ' /> : null}
        </div>
    )
}
